<template>
  <div>
    <v-card elevation="2">
      <v-card-title>Edición de la regla de conciliación {{ regla.nombre }}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="regla.nombre"
                label="Nombre de la Regla"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="regla.descripcion"
                label="Descripción"
                :rules="
                  rules.concat(
                    (v) => (v && v.length <= 250) || 'Máximo 250 caracteres'
                  )
                "
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="fuente"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Fuente"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingVariables"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingVariables"
                v-model="regla.variable_id"
                :items="variables"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Variable"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="fuente_comparacion"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Fuente Comparacion"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingVariablesComparacion"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingVariablesComparacion"
                v-model="regla.variable_comparacion_id"
                :items="variablesComparacion"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Variable Comparación"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" md="2">
              <v-switch v-model="regla.activo" :label="`Estado`"></v-switch>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                v-if="!regla.activo"
                v-model="regla.causaInactivacion"
                label="Causa de la inactivación"
                :rules="
                  rules.concat(
                    (v) => (v && v.length <= 250) || 'Máximo 250 caracteres'
                  )
                "
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReglaConciliadorService from "@/services/reglaConciliador.service";
import VariableService from "@/services/variable.service";
import LayoutService from "@/services/layout.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      regla: {
        nombre: undefined,
        descripcion: undefined,
        variable_id: undefined,
        variable_comparacion_id: undefined,
        activo: true,
        causaInactivacion: undefined,
      },
      fuente: undefined,
      fuente_comparacion: undefined,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      ordenRules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 1) || "Min 1 caracteres",
      ],
      fuentes: [],
      variables: [],
      variablesComparacion: [],
      loadingLayouts: false,
      loadingVariables: false,
      loadingVariablesComparacion: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  components: {

  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    "fuente"(newVal) {
      this.fetchVariables(`?layout_id=${this.fuente}`);
    },
    "fuente_comparacion"(newVal) {
      this.fetchVariablesComparacion(`?layout_id=${this.fuente_comparacion}`);
    }
  },
  mounted() {

    if (!this.permissions.includes("Definición de reglas de conciliador"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_regla_conciliador")) {
      this.regla = JSON.parse(localStorage.getItem("datos_regla_conciliador"));
      this.fuente = this.regla.fuente_id
      this.fuente_comparacion = this.regla.fuente_comparacion_id
      this.regla.fuente_id = undefined
      this.regla.fuente_comparacion_id = undefined
    }

    this.fetchLayouts();
  },
  methods: {
    save() {
      this.loadingSave = true;
      // console.log(this.regla);
      // this.regla.id = 2
      if (this.regla.hasOwnProperty("id")) {
        ReglaConciliadorService.editItem(this.regla).then(
          (response) => {
            this.$router.push({ path: `/conciliador/reglas` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        ReglaConciliadorService.newItem(this.regla).then(
          (response) => {
            this.$router.push({ path: `/conciliador/reglas` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if (cadenaBusqueda == "" || cadenaBusqueda == undefined)
        cadenaBusqueda = "?activo=1";
      else cadenaBusqueda = cadenaBusqueda + "&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
            };
          });
          // this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchVariables(cadenaBusqueda) {
      this.loadingVariables = true;
      // VariableService.getItems(this.elemento.fuente_id, cadenaBusqueda).then(
      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          console.log(cadenaBusqueda)
          console.log('Variables:')
          console.log(response.data)
          this.variables = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
              archivo: x.archivo,
              archivo_id: x.archivo_id
              // layout_id: x.layout.id,
              // layout_nombre: x.layout.nombre,
            };
          });
          //   this.variables = response.data;
          this.loadingVariables = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingVariables = false;
        }
      );
    },
    fetchVariablesComparacion(cadenaBusqueda) {
      this.loadingVariablesComparacion = true;
      // VariableService.getItems(this.elemento.fuente_comparacion_id, cadenaBusqueda).then(
      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          this.variablesComparacion = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre,
              archivo: x.archivo,
              archivo_id: x.archivo_id
              // layout_id: x.layout.id,
              // layout_nombre: x.layout.nombre,
            };
          });
          //   this.variablesComparacion = response.data;
          this.loadingVariablesComparacion = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingVariablesComparacion = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/conciliador/reglas" });
    },
  },
};
</script>

<style>
</style>
